import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

// Reference:
// https://mui.com/material-ui/material-icons
const IconButton = ({ id, icon, size, align, className, onClick }) => {
  const buttonClasses = classNames({
    'btn-floating': true,
    [`btn-${size}`]: true,
    [`${align}`]: true,
    'waves-effect': true,
    'waves-light': true,
    red: true,
    [`${className}`]: className
  });

  return (
    <a
      id={id}
      role="button"
      tabIndex={0}
      className={buttonClasses}
      onClick={onClick}
    >
      <i className="material-icons">{icon}</i>
    </a>
  );
};

IconButton.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  align: PropTypes.oneOf(['left', 'right', 'no-align']),
  className: PropTypes.string,
  onClick: PropTypes.func
};

IconButton.defaultProps = {
  id: 'icon-button',
  icon: 'add',
  size: 'medium',
  align: 'no-align',
  className: null,
  onClick: () => {}
};

export default IconButton;
