import React from 'react';
import PropTypes from 'prop-types';

import Page from '../../lib/layout/Page';
import Section from '../../lib/layout/Section';
import Column from '../../lib/layout/Column';

const SystemSummary = ({ system }) => {
  const formattedCompletionPerc = Math.round(Number(system.completionPercentage) * 100) / 100;

  return (
    <Page>
      <Column small={12}>
        <Section>
          <div className="system-summary">
            <Column small={2} />
            <Column small={10}>
              <h5>{system.name} (Ref: {system.id})</h5>
              <p>{system.description}</p>
              <br />
              <p>Completion Percentage: {formattedCompletionPerc}%</p>
            </Column>
          </div>
        </Section>
      </Column>
    </Page>
  );
};

SystemSummary.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    completionPercentage: PropTypes.string.isRequired,
    score: PropTypes.string
  }).isRequired,
};

SystemSummary.defaultProps = {
};

export default SystemSummary;
