import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TextareaAutosize from 'react-textarea-autosize';

const classNames = require('classnames');

import { generateGuid } from './lib/guid-generator';

const TextAreaInput = ({ field, form: { touched, errors }, id, prompt, placeholder, ...props }) => {
  const inputId = id || generateGuid();
  const inputValue = field.value || '';
  const placeholderValue = placeholder || '';
  const isTouched = _.get(touched, field.name);
  const errorMessage = _.get(errors, field.name);
  const validInput = !isTouched || (!errorMessage && isTouched);

  const inputClasses = classNames({
    'materialize-textarea': true,
    valid: validInput,
    invalid: !validInput,
  });

  const labelClasses = classNames({
    'text-input-label': true,
    active: ((inputValue.length > 0) || (placeholderValue.length > 0)),
  });

  let errorField = null;
  if (isTouched && errorMessage) {
    errorField = (
      <div className="form-error is-visible">
        <span>{errorMessage}</span>
      </div>
    );
  }

  return (
      <div className="input-field" >
        <i className="material-icons prefix">mode_edit</i>
        <TextareaAutosize id={inputId} className={inputClasses} placeholder={placeholder} {...field} {...props} />
        <label htmlFor={inputId} className={labelClasses}>
          <div className="label-text">
            {prompt}
          </div>
        </label>
        {errorField}
      </div>
  );
};

TextAreaInput.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  }),
  prompt: PropTypes.string,
};

TextAreaInput.defaultProps = {
  prompt: '',
  children: null
};

export default TextAreaInput;
