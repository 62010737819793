import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../layout/Tooltip';

const classNames = require('classnames');

const FormContainer = ({ className, cardClasses, contentClasses, title, tooltip, ...props }) => {
  const allCardClasses = classNames({
    card: true,
    [`${cardClasses}`]: true,
    [`${className}`]: className
  });

  const allContentClasses = classNames({
    'card-content': true,
    [`${contentClasses}`]: contentClasses
  });

  let renderedTitle = null;
  if (title && tooltip) {
    renderedTitle = (
        <span className="card-title">
          {`${title} `}
          <Tooltip position="right" text={tooltip}>
            <i className="tooltip-icon tiny material-icons">info_outline</i>
          </Tooltip>
        </span>
    );
  } else if (title) {
    renderedTitle = <span className="card-title">{title}</span>;
  }

  return (
    <div className={allCardClasses}>
      <div className={allContentClasses}>
        { renderedTitle }
        { props.children }
      </div>
    </div>
  );
};

FormContainer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  cardClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

FormContainer.defaultProps = {
  className: null,
  cardClasses: 'white',
  contentClasses: 'teal-text darken-4',
  title: null,
  tooltip: null,
  children: null
};

export default FormContainer;
