import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const ActionButton = ({ id, className, buttonText, onClick }) => {
  const buttonClasses = classNames({
    'waves-effect': true,
    'waves-light': true,
    [`${className}`]: className
  });

  const handleClick = (e) => {
    e.preventDefault()
    onClick(e);
  }

  return (
      <a
          id={id}
          role="button"
          href=""
          tabIndex={0}
          className={buttonClasses}
          onClick={handleClick}
      >
        {buttonText}
      </a>
  );
};

ActionButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

ActionButton.defaultProps = {
  id: undefined,
  className: undefined,
  buttonText: undefined,
  onClick: () => {}
};

export default ActionButton;
