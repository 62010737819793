import React, { useContext } from 'react';

import Card from '../../lib/layout/Card';

const SolutionPartnerCard = ({ partner }) => {
  const contactDetails = `${partner.contactName || ''}, ${partner.contactPhoneNumber || ''}, ${partner.contactEmail || ''}`;

  let contactSection = undefined;
  if (contactDetails.length > 10) {
    contactSection = (
        <p>
          Contact Information: {contactDetails};
        </p>
    )
  }
  return (
      <Card className="solution-partner-card"
            title={partner.name}
            linkClassName="solution-partner-link"
            linkTo={partner.websiteUrl}
            linkTitle="Visit"
      >
        <p>{partner.description}</p>
        <br/>
        {contactSection}
      </Card>
  );
}

export default SolutionPartnerCard;
