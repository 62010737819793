import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import _ from 'lodash';

const classNames = require('classnames');

import { formatErrors } from '../../utils/errorHelpers';

const options = {
  dismissible: true, // Modal can be dismissed by clicking outside of the modal
  opacity: 0.5, // Opacity of modal background
  inDuration: 300, // Transition in duration
  outDuration: 200, // Transition out duration
  startingTop: '4%', // Starting top style attribute
  endingTop: '10%' // Ending top style attribute
};

const Modal = ({ id, open, className, buttonText, onClose, children }) => {
  const [instance, setInstance] = useState(undefined);
  const modalClasses = classNames({
    modal: true,
    'modal-fixed-footer': true,
    openInitially: open,
    [`${className}`]: className
  });

  const buttonClasses = classNames({
    'modal-action': true,
    'waves-effect': true,
    'waves-green': true,
    'btn-flat': true
  });

  useEffect(() => {
    const elems = document.querySelectorAll(`#${id}`);
    const instances = M.Modal.init(elems, options);
    if (!instance) {
      setInstance(instances[0]);
    }

    if (instance) {
      if (open) {
        instance.open();
      } else {
        instance.close();
      }
    }

  }, [id, open]);

  let renderableChildren = children;
  if (typeof renderableChildren === 'object' && renderableChildren !== null) {
    if (_.has(renderableChildren, 'fullMessages')) {
      renderableChildren = _.join(renderableChildren.fullMessages, ',');
    } else if (_.has(renderableChildren, 'errors')) {
      renderableChildren = _.join(renderableChildren.errors, ',');
    } else {
      renderableChildren = formatErrors(renderableChildren);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    onClose(e);
  };

  return (
    <div id={id} className={modalClasses}>
      <div className="modal-content">
        {renderableChildren}
      </div>
      <div className="modal-footer">
        <a role="button" tabIndex="0" className={buttonClasses} onClick={handleClose}>
          {buttonText}
        </a>
      </div>
    </div>
  );
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.object,
    PropTypes.node
  ])
};

Modal.defaultProps = {
  open: false,
  buttonText: 'Ok',
  className: '',
  children: null
};

export default Modal;
