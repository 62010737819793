import React from 'react';
import { useParams } from 'react-router-dom';

import Preloader from '../../lib/layout/Preloader';
import EquipmentForm from './components/EquipmentForm';

import {
  useGetSystemQuery,
  useGetEquipmentListQuery
} from '../../lib/ascertis-api';

const EquipmentManager = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: list, isLoading: listLoading } = useGetEquipmentListQuery({ systemId });

  if (!system || listLoading) {
    return <Preloader />;
  }

  return (
    <div id="equipment-manager">
      <EquipmentForm system={system} equipmentList={list} />
    </div>
  );
};

EquipmentManager.propTypes = {};
EquipmentManager.defaultProps = {};

export default EquipmentManager;
