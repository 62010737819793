import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import Footer from '../../lib/layout/Footer';
import LogoutButton from '../User/LogoutButton';

const classNames = require('classnames');

const PublicLayout = ({ transparent, children }) => {
  const wrapperClasses = classNames({
    'app-container': true,
    'without-sidebar': true
  });

  const headerClasses = classNames({
    navbar: true,
    transparent
  });

  return (
    <div className={wrapperClasses}>
      <header>
        <nav className={headerClasses}>
          <div className="nav-wrapper">
            <Row>
              <Column small={2}>
                <ul className="left">
                  <Link to="/">Ascertis</Link>
                </ul>
              </Column>
              <Column small={4} />
              <Column small={6}>
                <ul className="right">
                  <li>
                    <Link to="/user/login">Sign In</Link>
                  </li>
                </ul>
              </Column>
            </Row>
          </div>
        </nav>
      </header>
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
};

PublicLayout.propTypes = {
  transparent: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

PublicLayout.defaultProps = {
  transparent: false,
  children: null
};

export default PublicLayout;
