import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const Page = ({ title, className, ...props }) => {
  const pageClasses = classNames({
    page: true,
    [`${className}`]: className
  });

  return (
    <div className={pageClasses}>
      {props.children}
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Page.defaultProps = {
  title: '',
  className: '',
  children: null
};

export default Page;
