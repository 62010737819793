import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { useUrlCredentials } from './lib/use-url-credentials';
import { useLogoutOnClose } from './lib/use-logout-on-close';
import * as shims from './lib/shims';

import UnauthenticatedRouter from './UnauthenticatedRouter';
import AuthenticatedRouter from './AuthenticatedRouter';
import { useAuthCookie } from './authenticationHooks';
import { selectUser } from '../../lib/userSlice';

const CyberSecurityBackgroundImage = require('../../assets/images/cybersecurity.png');

const getBackground = (location, authenticated) => {
  const currentPath = location.pathname;
  const imagePaths = ['/', '/home'];
  if ((_.includes(imagePaths, currentPath)) && (!authenticated)) {
    return { backgroundImage: `url(${CyberSecurityBackgroundImage})` };
  }
};

const App = () => {
  useLogoutOnClose();
  const location = useLocation();
  const user = useSelector(selectUser);

  // TODO: I don't know what this is supposed to do...
  useUrlCredentials(location.search);
  const authenticated = useAuthCookie();

  let router = <UnauthenticatedRouter />;
  if (authenticated && user) {
    router = <AuthenticatedRouter />;
  }

  const background = getBackground(location, authenticated);

  return (
    <div id="app" style={background}>
      {router}
    </div>
  );
};

export default App;
