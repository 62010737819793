import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Preloader from '../../lib/layout/Preloader';
import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import TextInput from '../../lib/forms/TextInput';
import SubmitButton from '../../lib/forms/SubmitButton';
import { useRequestUnlockMutation, useTokenUnlockQuery } from '../../lib/ascertis-api';

const Schema = Yup.object().shape({
  email: Yup.string().email().required()
});

const UnlockRequestForm = () => {
  const history = useHistory();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('unlock_token');
  const [tokenMissing, setTokenMissing] = useState(false);
  const [modalMessage, setModalMessage] = useState(undefined);
  const [requestUnlock] = useRequestUnlockMutation();
  const showModal = !_.isEmpty(modalMessage);

  const { data, isLoading, error } = useTokenUnlockQuery({ token }, { skip: !token });

  useEffect(() => {
    if (_.isEmpty(token)) {
      setTokenMissing(true);
    }
  }, [token])

  const goToLogin = () => {
    history.push('/user/login?unlocked=true');
  };

  const goToUnlockError = () => {
    history.push('/user/login?unlock_token_error=true');
  };

  const successMessage = 'Please check your email. You should receive a link to unlock your account.';
  const handleSubmit = async (formValues) => {
    await requestUnlock({ params: formValues }).unwrap()
      .then((payload) => {
        // Take any success actions here
        setModalMessage(successMessage);
      }).catch((error) => {
        setModalMessage(error);
      });
  };
  const handleClose = () => setModalMessage(undefined);

  const unlockForm = (
    <FormContainer title="Request Account Unlock">
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          return handleSubmit(values, actions);
        }}
      >
        {props => (
          <Form role="form">
            <Row>
              <Column small={12}>
                <Field
                  id="email-field"
                  name="email"
                  prompt="Email"
                  component={TextInput}
                />
              </Column>
            </Row>
            <Row className="form-button-container right-align">
              <SubmitButton>Request Unlock Email</SubmitButton>
            </Row>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );

  let body;
  if (tokenMissing) {
    body = unlockForm;
  } else if (data && !isLoading) {
    goToLogin();
  } else if (error && !isLoading) {
    goToUnlockError();
  } else {
    body = <Preloader />;
  }

  return (
    <div id="account-unlock-form">
      <Modal id="authentication-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      {body}
    </div>
  )
};

export default UnlockRequestForm;
