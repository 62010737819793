import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ThreatsTableRow from './ThreatsTableRow';

const ThreatsTable = ({ threats, system, controls }) => {
  const threatsRows = _.map(threats, (threat, id) => {
    return (
      <ThreatsTableRow key={`input-${threat.id}`} system={system} controls={controls} threat={threat} />
    );
  });

  return (
    <table id="threats-table" className="striped responsive-table">
      <thead className="header">
        <tr>
          <th>Threat Type</th>
          <th>Threat Source</th>
          <th>Controls</th>
          <th>Likelihood Of Occurence</th>
          <th>Impact Severity</th>
          <th>Calculated Risk</th>
        </tr>
      </thead>
      <tbody>
        {threatsRows}
      </tbody>
    </table>
  );
};

ThreatsTable.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  controls: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  threats: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    threatType: PropTypes.string.isRequired,
    threatSource: PropTypes.string.isRequired,
    calculatedRisk: PropTypes.string,
    impactSeverity: PropTypes.string,
    likelihoodOfOccurence: PropTypes.string
  })).isRequired
};

ThreatsTable.defaultProps = {
};

export default ThreatsTable;
