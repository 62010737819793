import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import DocumentsTableRow from './DocumentsTableRow';

const DocumentsTable = ({ system, documents }) => {
  const documentsRows = _.map(documents, (document, id) => {
    return (
      <DocumentsTableRow
        key={`input-${document.id}`}
        system={system}
        document={document}
      />
    );
  });

  let tableArea;
  if (_.size(documents) > 0) {
    tableArea = (
      <table id="documents-table" className="striped responsive-table">
        <thead className="header">
          <tr>
            <th>Document Name</th>
            <th>Controls</th>
            <th>Requirements</th>
          </tr>
        </thead>
        <tbody>
          {documentsRows}
        </tbody>
      </table>
    );
  }

  return (
    <div id="documents-table-wrapper">
      {tableArea}
    </div>
  );
};

DocumentsTable.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired
};

DocumentsTable.defaultProps = {
};

export default DocumentsTable;
