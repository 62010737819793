import React from 'react';
import { useParams } from 'react-router-dom';

import Page from '../../lib/layout/Page';
import Preloader from '../../lib/layout/Preloader';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import Section from '../../lib/layout/Section';

import CapabilitiesTable from './CapabilitiesTable';

import {
  useGetSystemQuery,
  useGetControlsQuery,
  useGetCapabilitiesQuery
} from '../../lib/ascertis-api';

const CapabilitiesPage = () => {
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: controls } = useGetControlsQuery({ systemId });
  const { data: capabilities } = useGetCapabilitiesQuery({ systemId });

  if (!system || !controls || !capabilities) {
    return <Preloader />;
  }

  const title = 'CMMC Practice Level Maturity';
  return (
    <div id="capabilities" className="app-container">
      <main>
        <Page>
          <Row>
            <Column small={1} />
            <Column small={10}>
              <Section>
                <h5>{title}</h5>
              </Section>
              <CapabilitiesTable
                system={system}
                capabilities={capabilities}
                controls={controls}
              />
            </Column>
            <Column small={1} />
          </Row>
        </Page>
      </main>
    </div>
  );
};

CapabilitiesPage.defaultProps = {};

export default CapabilitiesPage;
