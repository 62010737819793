import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';

const AuthenticatorQrCode = ({ otpAuthUrl }) => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  useEffect(() => {
    QRCode.toDataURL(otpAuthUrl).then(setQrCodeUrl);
  }, []);

  return (
    <div id="authenticator-qr-code" className="card large">
      <Row>
        <Column small={12}>
          <div className="card-content">
            <h4>
              Configuring Google Authenticator or Authy
            </h4>
            <div>
              <li>
                Install Google Authenticator (IOS - Android) or Authy (IOS -
                Android).
              </li>
              <li>In the authenticator app, select "+" icon.</li>
              <li>
                Select "Scan a barcode (or QR code)" and use the phone's camera
                to scan this barcode.
              </li>
            </div>

            <div>
              <h4>Scan QR Code</h4>
              <div className="flex justify-center">
                <img
                  className="block w-64 h-64 object-contain"
                  src={qrCodeUrl}
                  alt="qrcode url"
                />
              </div>
            </div>
          </div>
        </Column>
      </Row>
    </div>
  )
};

export default AuthenticatorQrCode;
