import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import TextInput from '../../lib/forms/TextInput';
import TextAreaInput from '../../lib/forms/TextAreaInput';
import RadioSelectInput from '../../lib/forms/RadioSelectInput';
import FormSelectDropdown from '../../lib/forms/FormSelectDropdown';
import MultiSelectInput from '../../lib/forms/MultiSelectInput';
import SelectInput from '../../lib/forms/SelectInput';
import ActionButton from '../../lib/forms/ActionButton';
import IconButton from '../../lib/forms/IconButton';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useUpdateSystemMutation } from '../../lib/ascertis-api';

const classNames = require('classnames');

const blankInterconnectedAgreement = {
  systemName: '',
  type: '',
  organization: '',
  agreement: '',
  securityLevel: '',
  authorizingOfficial: ''
};

const buildInterconnectedAgreement = (props, arrayHelpers, agreement, index) => {
  const typeOptions = [
    { value: 'CSP', label: 'Cloud Service Provider (CSP)' },
    { value: 'MSP', label: 'Managed Service Provider (MSP)' }
  ];

  const agreementOptions = [
    { value: 'MOU', label: 'Memorandum of Understanding' },
    { value: 'MOA', label: 'Memorandum of Agreement' },
    { value: 'ISA', label: 'Interconnection Security Agreement' }
  ];

  const cspSecurityLevelOptions = [
    { value: 'FedRAMP Moderate Certified', label: 'FedRAMP Moderate Certified' },
    { value: 'FedRAMP Moderate Equivalent', label: 'FedRAMP Moderate Equivalent' }
  ]

  const mspSecurityLevelOptions = [
    { value: 'CMMC Level 1', label: 'CMMC Level 1' },
    { value: 'CMMC Level 2', label: 'CMMC Level 2' }
  ]

  let securityLevelOptions = cspSecurityLevelOptions;
  if (agreement.type === 'MSP') {
    securityLevelOptions = mspSecurityLevelOptions;
  }

  const allCardClasses = classNames({
    card: true,
  });

  const allContentClasses = classNames({
    'card-content': true
  });

  return (
    <div key={index} className={allCardClasses}>
      <IconButton
        id={`remove-${index}-button`}
        icon="remove"
        size="small"
        align="right"
        onClick={() => arrayHelpers.remove(index)}
      />
      <div className={allContentClasses}>
        <span className="card-title">Interconnected Agreement</span>
        <Row>
          <Column small={6}>
            <Field
              id={`interconnectedAgreements-${index}-systemName-field`}
              name={`interconnectedAgreements[${index}].systemName`}
              prompt="System Name"
              component={TextInput}
            />
          </Column>

          <Column small={6}>
            <Field
              id={`interconnectedAgreements-${index}-type-field`}
              name={`interconnectedAgreements.${index}.type`}
              prompt="Type"
              options={typeOptions}
              component={RadioSelectInput}
              onChange={props.setFieldValue}
            />
          </Column>
        </Row>

        <Row>
          <Column small={6}>
            <Field
              id={`interconnectedAgreements-${index}-organization-field`}
              name={`interconnectedAgreements.${index}.organization`}
              prompt="Organization"
              component={TextInput}
            />
          </Column>

          <Column small={6}>
            <Field
              id={`interconnectedAgreements-${index}-agreement-field`}
              name={`interconnectedAgreements.${index}.agreement`}
              prompt="Agreement"
              options={agreementOptions}
              component={SelectInput}
            />
          </Column>
        </Row>

        <Row>
          <Column small={6}>
            <Field
              id={`interconnectedAgreements-${index}-securityLevel-field`}
              name={`interconnectedAgreements.${index}.securityLevel`}
              prompt="Security Level"
              options={securityLevelOptions}
              component={SelectInput}
            />
          </Column>

          <Column small={6}>
            <Field
              id={`interconnectedAgreements-${index}-authorizingOfficial-field`}
              name={`interconnectedAgreements.${index}.authorizingOfficial`}
              prompt="Authorizing Official"
              component={TextInput}
            />
          </Column>
        </Row>
      </div>
    </div>
  )
};

const UpdateGeneralInformationForm = ({ system }) => {
  const [modalMessage, setModalMessage] = useState(undefined);
  const [updateSystem] = useUpdateSystemMutation();
  const showModal = !_.isEmpty(modalMessage);

  const handleSubmit = async (formValues, formActions) => {
    console.log('submitting values!', formValues);
    await updateSystem({ systemId: system.id, params: formValues }).unwrap()
      .then((payload) => {
        setModalMessage('Successfully updated system.');
      }).catch((error) => {
        setModalMessage(error);
      });
  };

  const handleClose = () => setModalMessage(undefined);

  const Schema = Yup.object().shape({
    name: Yup.string().min(3).required(),
    description: Yup.string().min(3).required(),
    interconnectedAgreements: Yup.array(Yup.object().shape({
      systemName: Yup.string().required('System Name is required'),
      type: Yup.string().required('Type is required'),
      organization: Yup.string().required('Organization is required'),
      agreement: Yup.string().required('Agreement is required'),
      securityLevel: Yup.string().required('Security Level is required'),
      authorizingOfficial: Yup.string().required('Authorizing Official is required')
    }))
  });

  const name = system.name || '';
  const description = system.description || '';
  const operationalStatus = system.operationalStatus || '';
  const systemInformationType = system.systemInformationType || '';
  const informationCategories = system.informationCategories || '';
  const environment = system.environment || '';
  const mission = system.mission || '';
  const interconnectedAgreements = system.interconnectedAgreements || [];

  let informationCategoryOptions = [
    { label: 'CUI (Covered Defense Information and Controlled Technical Information)', value: 'CUI' },
    { label: 'Financial Information (Budgets, Banking records)', value: 'Financial Information' },
    { label: 'Law Enforcement Records (Criminal History on Employees)', value: 'Law Enforcement Records' },
    { label: 'Legal (Contracts, Collective bargaining, Judicial decisions)', value: 'Legal' },
    { label: 'Privacy (Personal, Military, Health, Performance Reviews)', value: 'Privacy' },
    { label: 'Proprietary (Patents, trade secrets,, R&D)', value: 'Proprietary' }
  ];

  if ((system.securityModel == 'CMMC') &&(system.cmmcLevel == 1)) {
    informationCategoryOptions = [
      { label: 'Financial Information (Budgets, Banking records)', value: 'Financial Information' },
      { label: 'Law Enforcement Records (Criminal History on Employees)', value: 'Law Enforcement Records' },
      { label: 'Legal (Contracts, Collective bargaining, Judicial decisions)', value: 'Legal' },
      { label: 'Privacy (Personal, Military, Health, Performance Reviews)', value: 'Privacy' },
      { label: 'Proprietary (Patents, trade secrets,, R&D)', value: 'Proprietary' }
    ];
  }

  const operationalStatusOptions = [
    { label: 'Operational', value: 'Operational' },
    { label: 'Under Development', value: 'Under Development' },
    { label: 'Major Modification', value: 'Major Modification' },
  ];

  const systemInformationTypeOptions = [
    { label: 'Major Application', value: 'Major Application' },
    { label: 'General Support System', value: 'General Support System' },
  ];

  const descriptionPlaceholder = 'A high level description of the major components, (e.g. "Windows/Mac workstation at the desktop, Hypervisor servers locally, and cloud service from AWS.")';
  const environmentPlaceholder = 'The physical environment of the system (e.g. "a secured building, a dedicated data center, or usage of a cloud provider.")';
  const missionPlaceholder = 'The primary purpose of the system (e.g. development of government documents, general business system, etc.)';

  return (
    <div id="update-system-form">
      <Modal id="notification-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      <FormContainer title="Update System Information">
        <Formik
          initialValues={{
            name: name,
            description: description,
            operationalStatus: operationalStatus,
            systemInformationType: systemInformationType,
            informationCategories: informationCategories,
            environment: environment,
            mission: mission,
            interconnectedAgreements: interconnectedAgreements
          }}
          validationSchema={Schema}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={12}>
                  <div className="divider" />
                  <div className="section">
                    <h6>General Information</h6>
                    <Row>
                      <Column small={12}>
                        <Field
                          id="name-field"
                          name="name"
                          prompt="Name"
                          component={TextInput}
                        />
                      </Column>
                    </Row>

                    <Row>
                      <Column small={12}>
                        <Field
                          id="description-field"
                          name="description"
                          prompt="Description"
                          placeholder={descriptionPlaceholder}
                          component={TextAreaInput}
                        />
                      </Column>
                    </Row>

                    <Row>
                      <Column small={12}>
                        <div className="input-field">
                          <Field
                            id="information-categories"
                            name="informationCategories"
                            prompt="Information Categories"
                            options={informationCategoryOptions}
                            component={MultiSelectInput}
                            onChange={props.setFieldValue}
                          />
                        </div>
                      </Column>
                    </Row>

                    <Row>
                      <Column small={12}>
                        <div className="input-field">
                          <Field
                            id="operational-status"
                            name="operationalStatus"
                            prompt="Operational Status"
                            options={operationalStatusOptions}
                            component={FormSelectDropdown}
                          />
                        </div>
                      </Column>
                    </Row>

                    <Row>
                      <Column small={12}>
                        <div className="input-field">
                          <Field
                            id="system-information-type"
                            name="systemInformationType"
                            prompt="Information Type"
                            options={systemInformationTypeOptions}
                            component={FormSelectDropdown}
                          />
                        </div>
                      </Column>
                    </Row>

                    <Row>
                      <Column small={12}>
                        <Field
                          id="environment-field"
                          name="environment"
                          prompt="Environment"
                          placeholder={environmentPlaceholder}
                          component={TextAreaInput}
                        />
                      </Column>
                    </Row>

                    <Row>
                      <Column small={12}>
                        <Field
                          id="mission-field"
                          name="mission"
                          prompt="Mission"
                          placeholder={missionPlaceholder}
                          component={TextAreaInput}
                        />
                      </Column>
                    </Row>

                    <Row>
                      <Column small={12}>
                        <FieldArray
                          name="interconnectedAgreements"
                          render={arrayHelpers => (
                            <div>
                              {props.values.interconnectedAgreements.map((agreement, index) => buildInterconnectedAgreement(props, arrayHelpers, agreement, index))}
                              <ActionButton
                                id="add-interconnected-agreement-button"
                                className="btn medium"
                                buttonText="Add External Service Provider"
                                onClick={() => arrayHelpers.push(blankInterconnectedAgreement)}
                              />
                            </div>
                          )}
                        />
                      </Column>
                    </Row>

                  </div>
                </Column>
              </Row>

              <Row className="form-button-container right-align">
                <SubmitButton>Submit</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
};

UpdateGeneralInformationForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    operationalStatus: PropTypes.string,
    systemInformationType: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func
};

UpdateGeneralInformationForm.defaultProps = {
  onSubmit: () => {}
};

export default UpdateGeneralInformationForm;
