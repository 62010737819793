import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Tooltip from '../layout/Tooltip';

const classNames = require('classnames');

const RadioSelect = ({ id, label, options, selected, valid, tooltipPosition, onSelect }) => {
  const inputId = id;
  const inputClasses = classNames({
    'radio-select': true,
    'with-gap': true,
    valid,
  });

  const labelClasses = classNames({
    'radio-select-label': true
  });

  const handleSelect = (evt) => {
    const selected = evt.target.value;
    onSelect(selected);
  };

  const radioArea = _.map(options, (option) => {
    let label = option.label;
    if (option.tooltip) {
      label = (
        <Tooltip position={tooltipPosition} text={option.tooltip}>
          {option.label}
        </Tooltip>
      )
    }
    return (
      <label key={option.value} className={labelClasses}>
        <input
          className={inputClasses}
          role="radio"
          name={inputId}
          type="radio"
          value={option.value}
          checked={option.value === selected}
          onChange={handleSelect}
        />
        <span>
          {label}
        </span>
      </label>
    )
  });

  return (
    <div className="radio-select">
      <label htmlFor={inputId} className={labelClasses}>
        <div className="label-text">
          {label}
        </div>
      </label>
      <div className="select-area">
        {radioArea}
      </div>
    </div>
  );
};

RadioSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  selected: PropTypes.string,
  valid: PropTypes.bool,
  tooltipPosition: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  onSelect: PropTypes.func
};

RadioSelect.defaultProps = {
  label: undefined,
  valid: true,
  tooltipPosition: 'top',
  onSelect: () => {}
};

export default RadioSelect;
