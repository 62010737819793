import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const SubmitButton = ({ disabled, className, children }) => {
  const buttonClasses = classNames({
    btn: true,
    'waves-effect': true,
    'waves-light': true,
    [`${className}`]: className
  });

  return (
    <button className={buttonClasses} type="submit" name="action">
      {children}
    </button>
  );
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

SubmitButton.defaultProps = {
  disabled: false,
  className: null,
  children: null
};

export default SubmitButton;
