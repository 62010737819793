import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const Column = ({ small, medium, large, xlarge, className, ...props }) => {
  const colClasses = classNames({
    col: true,
    [`s${small}`]: small,
    [`m${medium}`]: medium,
    [`l${large}`]: large,
    [`xl${xlarge}`]: xlarge,
    [`${className}`]: className
  });

  return (
    <div className={colClasses} {...props}>
      { props.children }
    </div>
  );
};

Column.propTypes = {
  small: PropTypes.number,
  medium: PropTypes.number,
  large: PropTypes.number,
  xlarge: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Column.defaultProps = {
  small: null,
  medium: null,
  large: null,
  xlarge: null,
  className: null,
  children: null
};

export default Column;
