import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AddButton from '../../lib/forms/AddButton';
import UpdateUserForm from './UpdateUserForm';

const KeyPersonForm = ({ system, users }) => {
  const [editing, setEditing] = useState(false);
  const [formUsers, setFormUsers] = useState(users);

  useEffect(() => {
    if (!editing) {
      let initialUsers = users;
      if (users.length === 0) {
        initialUsers = _.concat(users, [undefined]);
      }
      setFormUsers(initialUsers);
    }
  }, [users, editing]);

  const addNewUser = () => {
    setEditing(true);
    setFormUsers(_.concat(formUsers, [undefined]));
  };

  const tooltip = "A non-cyber security person responsible for the implementation of non-cyber security controls. E.g. Physical security controls or personnel screening controls";

  const handleSubmit = () => setEditing(false);

  let usersSection = _.map(formUsers, (user, index) => {
    return (
      <div key={index}>
        <UpdateUserForm
          id={`update-key-person-${index + 1}`}
          system={system}
          user={user}
          title="Key Person"
          tooltip={tooltip}
          roleName="KeyPerson"
          onSubmit={handleSubmit}
        />
      </div>
    );
  });

  if (formUsers.length === 0) {
    usersSection = (
      <div key={0}>
        <UpdateUserForm
          id={`update-key-person-1`}
          system={system}
          user={undefined}
          title="Key Person"
          tooltip={tooltip}
          roleName="KeyPerson"
          onSubmit={handleSubmit}
        />
      </div>
    );
  }

  return (
    <div id="key-person-container">
      {usersSection}
      <AddButton className="right" onClick={addNewUser} />
    </div>
  );
};

KeyPersonForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ).isRequired
};

KeyPersonForm.defaultProps = {};

export default KeyPersonForm;
