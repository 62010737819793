import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import M from 'materialize-css';

import Footer from '../../lib/layout/Footer';
import LogoutButton from '../User/LogoutButton';
import Breadcrumbs from './Breadcrumbs';

import { selectUser } from '../../lib/userSlice';

const classNames = require('classnames');

const AuthDefaultLayout = ({ children }) => {
  const user = useSelector(selectUser);
  const wrapperClasses = classNames({
    'app-container': true,
    'without-sidebar': true
  });

  const headerClasses = classNames({
    navbar: true
  });

  const loadDropdownJs = () => {
    const options = {};
    const elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, options);
  };

  useEffect(() => {
    loadDropdownJs();
  }, []);

  let solutionPartnerLink = undefined;
  const isSuperAdmin = user.superAdmin;
  if (isSuperAdmin) {
    solutionPartnerLink = (
      <li className="header-nav-item">
        <Link to="/solution_partners" className="solution-partners-link" id="solution-partners-header-link">Solution Partners</Link>
      </li>
    );
  }

  return (
    <div className={wrapperClasses}>
      <header>
        <ul id="account-dropdown" className="dropdown-content">
          <li><Link to="/account/edit">Update Account</Link></li>
          <li className="divider"></li>
          <li><LogoutButton /></li>
        </ul>

        <nav className={headerClasses}>
          <div className="nav-wrapper">
            <Link to="/" className="brand-logo">Ascertis</Link>
            <ul className="right header-nav-items">
              {solutionPartnerLink}
              <li className="header-nav-item">
                <a href="https://cmmcworkshop.com/welcome-to-the-cmmc-workshop/" target="_blank">
                  CMMC Workshop<i className="material-icons right">speaker_notes</i>
                </a>
              </li>
              <li className="header-nav-item">
                <a className="dropdown-trigger" href="#" data-target="account-dropdown">
                  Account / Logout<i className="material-icons right">arrow_drop_down</i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <Breadcrumbs />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
};

AuthDefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

AuthDefaultLayout.defaultProps = {
  children: null
};

export default AuthDefaultLayout;
