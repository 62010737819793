import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';

const classNames = require('classnames');

const ModalForm = ({ id, open, className, onClose, children }) => {
  const [instance, setInstance] = useState(undefined);
  const [openState, setOpenState] = useState(false);
  const handleClose = () => {
    setOpenState(false)
    onClose();
  }

  const options = {
    dismissible: true, // Modal can be dismissed by clicking outside of the modal
    opacity: 0.5, // Opacity of modal background
    inDuration: 300, // Transition in duration
    outDuration: 200, // Transition out duration
    startingTop: '4%', // Starting top style attribute
    endingTop: '10%', // Ending top style attribute
    onCloseStart: () => { handleClose(); } // Callback for Modal close
  };

  useEffect(() => {
    const elems = document.querySelectorAll(`#${id}`);
    const instances = M.Modal.init(elems, options);
    setInstance(instances[0])
  }, []);

  useEffect(() => {
    setOpenState(open);
    if (instance) {
      if (open) {
        instance.open();
      } else {
        instance.close();
      }
    }
  }, [open, instance]);

  const modalClasses = classNames({
    modal: true,
    'modal-form': true,
    openInitially: open,
    [`${className}`]: className
  });

  return (
    <div id={id} className={modalClasses}>
      <div className="modal-content">
        { children }
      </div>
    </div>
  );
};

ModalForm.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ModalForm.defaultProps = {
  open: false,
  className: '',
  onClose: () => {},
  children: null
};

export default ModalForm;
