import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const Row = ({ className, ...props }) => {
  const rowClasses = classNames({
    row: true,
    [`${className}`]: className
  });

  return (
    <div className={rowClasses} {...props}>
      {props.children}
    </div>
  );
};

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Row.defaultProps = {
  className: '',
  children: null
};

export default Row;
