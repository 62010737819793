import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import Preloader from '../../lib/layout/Preloader';
import Page from '../../lib/layout/Page';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import Section from '../../lib/layout/Section';
import Pagination from '../../lib/widgets/Pagination';
import ThreatsTable from './ThreatsTable';

import {
  useGetSystemQuery,
  useGetControlsQuery,
  useGetThreatsQuery,
} from '../../lib/ascertis-api';

const ThreatsPage = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { systemId } = useParams();
  const { data: system } = useGetSystemQuery({ systemId });
  const { data: controls } = useGetControlsQuery({ systemId });
  const { data: threats } = useGetThreatsQuery({ systemId });

  if (!system || !controls || !threats) {
    return <Preloader />;
  }

  const sortedItems = _.sortBy(threats, (threat) => threat.orderNumber);

  const title = 'Threat Table';
  let pagedItems = [];
  let itemPages = [];
  if (sortedItems.length > 0) {
    itemPages = _.chunk(sortedItems, 10);
    pagedItems = itemPages[pageNumber - 1];
  }

  return (
    <div id="threats" className="app-container">
      <main>
        <Page>
          <Row>
            <Column small={1} />
            <Column small={10}>
              <Section>
                <h5>{title}</h5>
              </Section>
              <Pagination
                numberOfPages={itemPages.length}
                currentPageNumber={pageNumber}
                onClick={setPageNumber}
              />
              <ThreatsTable
                system={system}
                threats={pagedItems}
                controls={controls}
              />
              <Pagination
                numberOfPages={itemPages.length}
                currentPageNumber={pageNumber}
                onClick={setPageNumber}
              />
            </Column>
            <Column small={1} />
          </Row>
        </Page>
      </main>
    </div>
  );
}

ThreatsPage.propTypes = {};

ThreatsPage.defaultProps = {};

export default ThreatsPage;
