import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import TextInput from '../../lib/forms/TextInput';
import PasswordInput from '../../lib/forms/PasswordInput';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useSignInMutation } from '../../lib/ascertis-api';
import { setUser } from '../../lib/userSlice';

const EmailSignInForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalMessage, setModalMessage] = useState(undefined);
  const [otpRequired, setOtpRequired] = useState(false);
  const [mfaType, setMfaType] = useState(undefined);
  const [signIn] = useSignInMutation();
  const showModal = !_.isEmpty(modalMessage);

  const handleSubmit = async (formValues) => {
    await signIn({ params: formValues }).unwrap()
      .then((payload) => {
        // Take any success actions here
        const userData = payload.data;
        dispatch(setUser(userData));
        setModalMessage(undefined);
        history.push(`/systems`);
      }).catch((error) => {
        if (otpRequired) {
          setModalMessage(error);
        } else {
          if (error.needOtp) {
            setOtpRequired(true);
            setMfaType(error.mfaType);
          } else {
            setModalMessage(error);
          }
        }
      });
  };

  const handleClose = () => setModalMessage(undefined);

  const initialSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().min(3).required()
  });
  const otpSchema = Yup.object().shape({
    otpAttempt: Yup.string().min(3).required()
  });
  const initialDefaultValues = {
    email: '',
    password: '',
    otpAttempt: ''
  };
  const otpDefaultValues = {
    otpAttempt: ''
  };

  const formTitle = otpRequired ? 'One Time Passcode' : 'Log In';
  const validationSchema = otpRequired ? otpSchema : initialSchema;
  const initialValues = otpRequired ? otpDefaultValues : initialDefaultValues;

  const initialFields = (
    <div>
      <Row>
        <Column small={12}>
          <Field id="email" name="email" prompt="Email" component={TextInput} />
        </Column>
      </Row>
      <Row>
        <Column small={12}>
          <Field id="password" name="password" prompt="Password" component={PasswordInput} />
        </Column>
      </Row>
    </div>
  );

  let otpMessage = 'Please enter your one-time MFA passcode.';
  if (mfaType === 'email') {
    otpMessage = 'Your one-time MFA passcode has been sent to your email.'
  } else if (mfaType === 'sms') {
    otpMessage = 'Your one-time MFA passcode has been sent via SMS.'
  } else if (mfaType === 'authenticator') {
    otpMessage = 'Your one-time MFA passcode is available in your authenticator app.'
  }

  const otpFields = (
    <div>
      <Row>
        <Column small={12}>
          <Field id="otpAttempt" name="otpAttempt" prompt="One Time Passcode" component={TextInput} />
        </Column>
      </Row>
      <Row>
        <Column small={12}>
          <p>{otpMessage}</p>
        </Column>
      </Row>
    </div>
  );

  const formFields = otpRequired ? otpFields : initialFields;

  return (
    <div id="email-sign-in-form">
      <Modal id="authentication-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      <FormContainer title={formTitle}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              {formFields}
              <Row className="form-button-container right-align">
                <SubmitButton>Sign In</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
}

EmailSignInForm.propTypes = {
  onSubmit: PropTypes.func
};

EmailSignInForm.defaultProps = {
  onSubmit: () => {}
};

export default EmailSignInForm;
