import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import FormContainer from '../../lib/forms/FormContainer';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useGetControlsQuery, useCreateReportMutation } from '../../lib/ascertis-api';


const Schema = Yup.object().shape({
});

const SprsReportForm = ({ system }) => {
  const { data: controls } = useGetControlsQuery({ systemId: system.id });
  const [createReport] = useCreateReportMutation();
  const [modalMessage, setModalMessage] = useState(undefined);
  const showModal = !_.isEmpty(modalMessage);

  const handleSubmit = async (formValues) => {
    const control = _.find(controls, (c) => c.controlIdNumber === 'CA.2.157');

    if (control && !control.notApplicable && system?.cmmcLevel === 2 && !control.allRequirementsMet) {
      setModalMessage(control?.cmmcScorecardComment);
    } else {
      const params = { reportType: 'sprs', options: formValues };
      await createReport({ systemId: system.id, params }).unwrap()
        .then((payload) => {
          setModalMessage('Report created.');
        }).catch((error) => {
          setModalMessage(error);
        });
    }
  };

  const handleClose = () => setModalMessage(undefined);

  return (
    <div id="new-ste-report-form">
      <FormContainer title="Generate New SPRS Report">
        <Modal id="report-modal" open={showModal} onClose={handleClose}>
          { modalMessage }
        </Modal>
        <Formik
          initialValues={{ }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row className="form-button-container right-align">
                <SubmitButton>Submit</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
}

SprsReportForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

SprsReportForm.defaultProps = {
};

export default SprsReportForm;
