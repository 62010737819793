import React from 'react';
import PropTypes from 'prop-types';

import UpdateUserForm from './UpdateUserForm';

const CertifierForm = ({ system, user }) => {
  const tooltip = "The independent third party that performs a Security Test and Evaluation and writes the certification letter.";

  return (
    <div id="certifier-container">
      <UpdateUserForm
        system={system}
        user={user}
        title="Certifier"
        tooltip={tooltip}
        roleName="Certifier"
      />
    </div>
  );
};

CertifierForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  })
};

CertifierForm.defaultProps = {
  onSubmit: () => {}
};

export default CertifierForm;
