import React from 'react';
import PropTypes from 'prop-types';

import UpdateUserForm from './UpdateUserForm';

const AuthorizingOfficerForm = ({ system, user }) => {
  const tooltip = "The person that accepts the risk for the company that the system is operating with due diligence for the information being processed, transmitted or stored. Risk is usually determined by independent testing and follows the recommendation of a certification letter.";

  return (
    <div id="authorizing-officer-container">
      <UpdateUserForm
        system={system}
        user={user}
        title="Authorizing Officer"
        tooltip={tooltip}
        roleName="AuthorizingOfficer"
      />
    </div>
  );
};

AuthorizingOfficerForm.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  })
};

AuthorizingOfficerForm.defaultProps = {
  onSubmit: () => {}
};

export default AuthorizingOfficerForm;
