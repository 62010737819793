import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const CheckBox = ({ id, label, checked, onChange, ...props }) => {
  const inputId = id;
  const inputClasses = classNames({
    'checkbox-input': true,
    'filled-in': true,
    valid: true,
  });

  const labelClasses = classNames({
    'checkbox-input-label': true
  });

  return (
    <div className="input-checkbox">
      <label htmlFor={inputId}>
        <input
          name={inputId}
          id={inputId}
          type="checkbox"
          className={inputClasses}
          checked={checked}
          onChange={onChange}
        />
        <span className={labelClasses}>{label}</span>
      </label>
    </div>
  );
};

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

CheckBox.defaultProps = {
  onChange: () => {}
};

export default CheckBox;
