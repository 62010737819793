import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Modal from '../../lib/layout/Modal';
import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import TextInput from '../../lib/forms/TextInput';
import TextAreaInput from '../../lib/forms/TextAreaInput';
import FormSelectDropdown from '../../lib/forms/FormSelectDropdown';
import ImageUpload from '../../lib/forms/ImageUpload';
import SubmitButton from '../../lib/forms/SubmitButton';

import { useCreateSolutionPartnerMutation } from '../../lib/ascertis-api';

const Schema = Yup.object().shape({
  partnerType: Yup.string().required(),
  name: Yup.string().required(),
  description: Yup.string().required(),
  websiteUrl: Yup.string().required(),
  logo: Yup.object().shape({
    name: Yup.string().required(),
    base64: Yup.string().required()
  })
});

const partnerOptions = [
  { label: 'Network Monitoring', value: 'network_monitoring' },
  { label: 'Identity Management', value: 'identity_management' },
  { label: 'Penetration Testing', value: 'penetration_testing' },
  { label: 'Insider Threat', value: 'insider_threat' },
];

const NewSolutionPartnerForm = () => {
  const [createPartner] = useCreateSolutionPartnerMutation();
  const [modalMessage, setModalMessage] = useState(undefined);
  const showModal = !_.isEmpty(modalMessage);

  const handleSubmit = async (formValues) => {
    let formattedValues = _.omit(formValues, 'logo');
    formattedValues.logoType = formValues.logo.type;
    formattedValues.logoBase64 = formValues.logo.base64;

    await createPartner({ params: formattedValues }).unwrap()
      .then((payload) => {
        setModalMessage('Successfully added solution partner');
      }).catch((error) => {
        setModalMessage(error);
      });
  };
  const handleClose = () => setModalMessage(undefined);

  return (
    <div id="new-solution-partner-form">
      <Modal id="notification-modal" open={showModal} onClose={handleClose}>
        { modalMessage }
      </Modal>
      <FormContainer title="Add Solution Partner">
        <Formik
          initialValues={{
            partnerType: '',
            name: '',
            description: '',
            websiteUrl: '',
            contactName: '',
            contactEmail: '',
            contactPhoneNumber: '',
            logo: {
              name: '',
              base64: ''
            }
          }}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={4}>
                  <Field
                    id="partner-type-field"
                    name="partnerType"
                    prompt="Partner Type"
                    className="solutions-partner-select"
                    options={partnerOptions}
                    component={FormSelectDropdown}
                  />
                </Column>
                <Column small={4}>
                  <Field
                    id="name-field"
                    name="name"
                    prompt="Name"
                    component={TextInput}
                  />
                </Column>
                <Column small={4}>
                  <Field
                    id="website-url-field"
                    name="websiteUrl"
                    prompt="Website URL"
                    component={TextInput}
                  />
                </Column>
              </Row>
              <Row>
                <Column small={12}>
                  <Field
                    id="description-field"
                    name="description"
                    prompt="Description"
                    component={TextAreaInput}
                  />
                </Column>
              </Row>
              <Row>
                <Column small={4}>
                  <Field
                    id="contact-name-field"
                    name="contactName"
                    prompt="Contact Name"
                    component={TextInput}
                  />
                </Column>
                <Column small={4}>
                  <Field
                    id="contact-email-field"
                    name="contactEmail"
                    prompt="Contact Email"
                    component={TextInput}
                  />
                </Column>
                <Column small={4}>
                  <Field
                    id="contact-phone-number-field"
                    name="contactPhoneNumber"
                    prompt="Contact Phone Number"
                    component={TextInput}
                  />
                </Column>
              </Row>
              <Row>
                <Column small={12}>
                  <h5>Upload logo</h5>
                  <Field
                    id="attach-logo"
                    name="logo"
                    component={ImageUpload}
                    onChange={props.setFieldValue}
                  />
                </Column>
              </Row>
              <Row className="form-button-container right-align">
                <SubmitButton>Submit</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
}

export default NewSolutionPartnerForm;
