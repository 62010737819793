import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import * as validators from '../../utils/validators';

import Row from '../../lib/layout/Row';
import Column from '../../lib/layout/Column';
import FormContainer from '../../lib/forms/FormContainer';
import SubmitButton from '../../lib/forms/SubmitButton';
import TextInput from '../../lib/forms/TextInput';

import { useCreateSystemUserMutation, useUpdateSystemUserMutation } from '../../lib/ascertis-api';

const UpdateUserForm = ({ system, user, roleName, id, title, tooltip, onSubmit }) => {
  const [formUser, setFormUser] = useState({});
  const [savedText, setSavedText] = useState('');
  const [addingNewUser, setAddingNewUser] = useState(false);
  const [createUser] = useCreateSystemUserMutation();
  const [updateUser] = useUpdateSystemUserMutation();

  useEffect(() => {
    if (user === undefined) {
      setFormUser({});
      setAddingNewUser(true);
    } else {
      setFormUser(user);
      setAddingNewUser(false);
    }
  }, [user]);

  const handleClick = () => {
    setFormUser({});
    setAddingNewUser(true);
  };

  const validated = formUser.validated || false;
  let formId = id;
  if (formId === undefined) {
    const rawId = `update-${title}-form`;
    formId = rawId.replace(/\./g, '-');
  }

  const Schema = Yup.object().shape({
    name: Yup.string().min(3).required(),
    title: Yup.string().required(),
    email: Yup.string().email().required(),
    phoneNumber: Yup.string().phone(),
    companyName: Yup.string()
  });

  const handleSubmit = async (formValues) => {
    if (addingNewUser) {
      formValues.roles = [roleName];
      await createUser({ systemId: system.id, params: formValues }).unwrap()
        .then((payload) => {
          setSavedText('Saved user data successfully.');
          onSubmit();
        }).catch((error) => {
          setSavedText('Error saving user data.');
        });
    } else {
      formValues.roles = user.roles;
      await updateUser({ systemId: system.id, userId: user.id, params: formValues }).unwrap()
        .then((payload) => {
          setSavedText('Saved user data successfully.');
          onSubmit();
        }).catch((error) => {
          setSavedText('Error saving user data.');
        });
    }
  };

  const handleRemoveRole = async () => {
    const formValues = {};
    await updateUser({ systemId: system.id, userId: user.id, params: formValues }).unwrap()
      .then((payload) => {
        setSavedText('Saved user data successfully.');
      }).catch((error) => {
        setSavedText('Error saving user data.');
      });
  };

  const unvalidatedArea = (
    <div className="user-note">
      <p>Note: user will be locked for editing once account is validated.</p>
    </div>
  );

  const validatedArea = (
    <div className="user-note">
      <p>User name and email cannot be edited once the NDA has been signed.</p>
      <p>To add a new user in this role, click <button onClick={handleClick}>here</button></p>
    </div>
  );

  const valdiatedKeyPersonArea = (
    <div className="user-note">
      <p>User name and email cannot be edited once the NDA has been signed.</p>
    </div>
  );

  const nonKeyPersonNote = validated ? validatedArea : unvalidatedArea;
  const keyPersonNote = validated ? valdiatedKeyPersonArea : unvalidatedArea;
  const userNote = roleName === 'KeyPerson' ? keyPersonNote : nonKeyPersonNote;

  let savedTextSection = undefined;
  if (savedText.length > 0) {
    savedTextSection = <div><br/>{savedText}<br/></div>
  }

  return (
    <div id={formId}>
      <FormContainer title={title} tooltip={tooltip}>
        <Formik
          initialValues={{
            name: formUser.name || '',
            title: formUser.title || '',
            email: formUser.email || '',
            phoneNumber: formUser.phoneNumber || '',
            companyName: formUser.companyName || ''
          }}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            return handleSubmit(values, actions);
          }}
        >
          {props => (
            <Form role="form">
              <Row>
                <Column small={12}>
                  <div className="divider" />
                  {savedTextSection}
                  <div className="divider" />
                  <div className="section">
                    <Row>
                      <Column small={6}>
                        <Field
                          id="name-field"
                          name="name"
                          prompt="Name"
                          component={TextInput}
                          disabled={validated}
                        />
                      </Column>

                      <Column small={6}>
                        <Field
                          id="title-field"
                          name="title"
                          prompt="Title"
                          component={TextInput}
                        />
                      </Column>
                    </Row>

                    <Row>
                      <Column small={6}>
                        <Field
                          id="email-field"
                          name="email"
                          prompt="Email"
                          component={TextInput}
                          disabled={validated}
                        />
                      </Column>

                      <Column small={6}>
                        <Field
                          id="phone-number-field"
                          name="phoneNumber"
                          prompt="Phone"
                          component={TextInput}
                        />
                      </Column>
                    </Row>

                    <Row>
                      <Column small={6}>
                        <Field
                          id="company-name-field"
                          name="companyName"
                          prompt="Company Name"
                          component={TextInput}
                        />
                      </Column>
                    </Row>
                  </div>
                </Column>
              </Row>

              {userNote}
              <Row className="form-button-container right-align">
                <SubmitButton>Submit</SubmitButton>
              </Row>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
};

UpdateUserForm.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    companyName: PropTypes.string
  }),
  id: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  onSubmit: PropTypes.func
};

UpdateUserForm.defaultProps = {
  user: undefined,
  id: undefined,
  title: undefined,
  tooltip: undefined,
  onSubmit: () => {}
};

export default UpdateUserForm;
