import React from 'react';

import Page from '../../lib/layout/Page';
import Container from '../../lib/layout/Container';
import Column from '../../lib/layout/Column';
import Row from '../../lib/layout/Row';
import Card from '../../lib/layout/Card';

const HomePage = () => {
  const executiveOrderDesc = `Executive Order 13556 "Controlled \
Unclassified Information" (the Order), establishes a program for \
managing CUI across the Executive branch and designates the National \
Archives and Records Administration (NARA) as Executive Agent to \
implement the Order and oversee agency actions to ensure compliance.`;

  const toolDesc = `This tool will help you understand gaps in \
your existing systems and infrastructure as it relates to this \
Executive Order.`;

  return (
    <Page>
      <Container>
        <Row>
          <Column small={12} medium={6}>
            <Card title="Executive Order 13556">
              <p>
                {executiveOrderDesc}
              </p>
              <br />
              <div className="divider" />
              <br />
              <p>
                {toolDesc}
              </p>
            </Card>
          </Column>
        </Row>
      </Container>
    </Page>
  );
};

export default HomePage;
