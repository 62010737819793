import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useDropdownEffect } from './lib/use-dropdown-effect';
const classNames = require('classnames');

const MultiSelectInput = ({
  field, form: { touched, errors }, id, prompt, options, className, hidden
}) => {

  const inputId = id;
  useDropdownEffect(id, id, options, hidden);
  const inputValue = field.value || [];
  const isTouched = _.get(touched, field.name);
  const errorMessage = _.get(errors, field.name);
  const validInput = !isTouched || (!errorMessage && isTouched);

  const divClasses = classNames({
    'input-field': true,
    'multi-select-div': true,
    [`${className}`]: className
  });

  const labelClasses = classNames({
    'text-input-label': true,
    'multi-select': true,
    active: true
  });

  let errorField = null;
  if (isTouched && errorMessage) {
    errorField = (
      <div className="form-error is-visible">
        <span>{errorMessage}</span>
      </div>
    );
  }

  const optionsHtml = _.map(options, (option) => {
    return (
        <option key={option.value} value={option.value}>{option.label}</option>
    );
  });

  return (
    <div className={divClasses}>
      <select id={inputId} multiple={true} {...field} value={inputValue} >
        <option value="" disabled>Select</option>
        {optionsHtml}
      </select>
      <label htmlFor={inputId}>{prompt}</label>
      {errorField}
    </div>
  );
}

MultiSelectInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ])
  }),
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  }),
  id: PropTypes.string,
  options: PropTypes.array,
  prompt: PropTypes.string,
  className: PropTypes.string,
  hidden: PropTypes.bool
};

MultiSelectInput.defaultProps = {
  options: [],
  hidden: false,
  prompt: ''
};

export default MultiSelectInput;
